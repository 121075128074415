// FancyBox Settings
Fancybox.defaults.placeFocusBack = 0;
Fancybox.defaults.autoFocus = false;
Fancybox.defaults.trapFocus = false;
Fancybox.defaults.dragToClose = false;

Fancybox.bind("[data-fancybox]", {});

// ScrollReveal 
import ScrollReveal from 'scrollreveal';
window.ScrollReveal = ScrollReveal;
ScrollReveal({ afterReveal: function (el) { el.removeAttribute('style'); el.removeAttribute('data-sr-id'); } });
ScrollReveal().reveal('.srb', { interval: 75, distance: '60px', duration: 1000, viewFactor: 0.50, origin: 'bottom', mobile: false });
ScrollReveal().reveal('.srt', { interval: 75, distance: '60px', duration: 1000, viewFactor: 0.50, origin: 'top', mobile: false });
ScrollReveal().reveal('.srl', { interval: 75, distance: '30px', duration: 1000, viewFactor: 0.25, origin: 'left', mobile: false });
ScrollReveal().reveal('.srr', { interval: 75, distance: '30px', duration: 1000, viewFactor: 0.25, origin: 'right', mobile: false });
ScrollReveal().reveal('.srb-all > *', { interval: 150, distance: '60px', duration: 1000, viewFactor: 0.25, origin: 'bottom', mobile: false });

// Define Custom Elements
class wrapper extends HTMLElement { constructor() { super(); } }
customElements.define("penta-yazilim", wrapper);

// Header Height
let r = document.querySelector(':root');
r.style.setProperty('--header-height', document.querySelector('.header-field').offsetHeight + 'px');

// Window Height Class
let windowHeight = document.querySelectorAll('.h-win');
windowHeight.forEach(el => { el.style.height = `${window.innerHeight}px`; });

// Widen to Viewport
let widenToViewport = document.querySelectorAll('.widen-side')
if (widenToViewport.length) {
    widenToViewport.forEach(element => {
        let container = element.closest('.wrapper');
        let widenWrapper = element.closest('.widen-wrapper');
        let size = ((window.innerWidth - container.offsetWidth) / 2) + widenWrapper.offsetWidth;
        element.style.width = `${size}px`
    });
}

// Smart Menu
let headerField = document.querySelector('.header-field');
let headerHeight = headerField.offsetHeight;
let currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
if (currentScrollTop >= (headerHeight / 5)) { headerField.classList.add('is-hidden'); headerField.classList.add('is-fixed'); }
else { headerField.classList.remove('is-hidden'); headerField.classList.remove('is-fixed'); }

let lastScrollTop = 0;
let stickyObjects = document.querySelectorAll('.sticky-el');
window.addEventListener('scroll', function () {
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    if (scrollTop > lastScrollTop && scrollTop > (headerHeight / 5)) {
        stickyObjects.forEach(el => { el.style.top = `45px`; });
        headerField.classList.add('is-hidden'); headerField.classList.add('is-fixed');
    }
    else {
        stickyObjects.forEach(el => { el.style.top = `${headerHeight + 45}px`; });
        if (scrollTop > (headerHeight / 5)) { headerField.classList.remove('is-hidden'); headerField.classList.add('is-fixed'); }
        else { headerField.classList.remove('is-hidden'); headerField.classList.remove('is-fixed'); }
    }
    lastScrollTop = scrollTop;
}, false);

// Menu Hover Effect
let menuBGBox = document.querySelector('.header-field .tools-wrapper .box');
let menuItems = document.querySelectorAll('.header-field .tools-wrapper .menu-wrapper .menu > li');
menuItems.forEach(li => {
    li.addEventListener('mouseenter', () => {
        menuBGBox.classList.add('is-active');
        gsap.to(menuBGBox, { ease: 'expo.inOut', duration: 0.6, x: li.offsetLeft, width: li.offsetWidth });
    }, false);
});
document.querySelector('.header-field .tools-wrapper .menu-wrapper').addEventListener('mouseleave', () => { menuBGBox.classList.remove('is-active'); }, false);

// Mobile Menu Trigger
document.querySelector('.mobile-menu-wrapper .trigger').addEventListener('click', () => {
    document.querySelector('.mobile-menu-wrapper').classList.toggle('is-active');
    document.querySelector('body').classList.toggle('overflow-hidden');
    document.querySelector('.menu-background').classList.toggle('is-active')
}, false);

// Mobile Sub Menu Trigger
document.querySelectorAll('.mobile-menu-wrapper .mobile-menu .submenu-trigger').forEach(trigger => {
    trigger.addEventListener('click', () => {
        trigger.classList.toggle('is-active');

        // Burada li elementini hedefliyoruz
        const liElement = trigger.closest('li.menuli');
        if (liElement) {
            liElement.classList.toggle('is-active');
        }
    }, false);
});
let footerSideValue = `${document.querySelector('.footer-field .brand-wrapper')}`;
document.querySelector('.scroll-top').style.left = footerSideValue;
document.querySelector('.footer-field .side-background').style.width = footerSideValue;
document.querySelector('.scroll-top').addEventListener('click', function() {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
});
// Close Cookie
let cookie = document.querySelector(".cookie-box");
let cookieClose = document.querySelectorAll(".close-cookie");
let cookieAccept = document.querySelectorAll(".accept-cookie");

cookieClose.forEach((close) => {
    close.addEventListener("click", () => {
        cookie.classList.add("!translate-y-[125%]");
    });
});

