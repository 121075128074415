// Swiper -----------------------------------------------------------
import Swiper, { Navigation, Pagination, EffectCreative, Parallax, Autoplay, Grid, Thumbs } from 'swiper';
window.Swiper = Swiper;

// HOMEPAGE CAROUSEL
let heroCarouselParams = {
    modules: [Navigation, Parallax, Autoplay, Pagination],
    speed: 1000,
    slidesPerView: 1,
    parallax: true,
    resistance: true,
    resistanceRatio: 0,
    pagination: {
        el: '.swiper-pagination',
        clickable: true
    },
    autoplay: {
        delay: 10000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
    },
}
let heroCarousel = new Swiper('.hero-carousel', heroCarouselParams);

// HOMEPAGE PROJECT CAROUSEL
let featuredProjectsCarouselParams = {
    modules: [Autoplay, Parallax, Pagination, Navigation],
    speed: 1000,
    centeredSlides: true,
    loop: true,
    slidesPerView: 1.5,
    resistance: true,
    resistanceRatio: 0,
    watchSlidesProgress: true,
    autoplay: {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
    },
    navigation: {
        nextEl: ".featured-project-next",
        prevEl: ".featured-project-prev"
    },
    pagination: {
        type: 'progressbar',
        el: '.featured-projects-progressbar',
    },
    breakpoints: {
        0: { slidesPerView: 1 },
        426: { slidesPerView: 1.25 },
        769: { slidesPerView: 1.5, }
    }
}
new Swiper('.featured-projects-carousel', featuredProjectsCarouselParams);

// HOMEPAGE REFERANCE
let referencestopCarousel = new Swiper(".references-top-carousel", {
    modules: [Autoplay, Navigation],
    slidesPerView: 4,
    speed: 5000,
    spaceBetween: 30,
    loop: true,
    allowTouchMove: false,
 
    autoplay: {
        delay: 1000,
        disableOnInteraction: false
    },
    breakpoints: {
        0: {
            slidesPerView: 2,
        },
        481: {
            slidesPerView: 2,
        },
        991: {
            slidesPerView: 2,
        },
        1024: {
            slidesPerView: 3,
        },
        1366: {
            slidesPerView: 5,
        },
    },
});

// HOMEPAGE ABOUT CAROUSEL
var homepageAbout = new Swiper(".about-carousel", {
    modules: [Navigation, Parallax, Autoplay, Pagination],
    speed: 1000,
    slidesPerView: 1,
    parallax: true,
    resistance: true,
    resistanceRatio: 0,
    pagination: {
        el: '.swiper-pagination-2',
        clickable: true
    },
    autoplay: {
        delay: 10000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
    },
})

// SINGLE SERVICE MOBILE CAROUSEL
var featuredBlogCarousel = new Swiper(".about-image-carousel", {
    modules: [Autoplay, Pagination, Navigation, Parallax],
    slidesPerView: 1,
    speed: 1000,
    spaceBetween: 0,
    loop: false,
    parallax: true,
    autoplay: {
        delay: 5000,
    },
    navigation: {
        nextEl: ".about-image-next",
        prevEl: ".about-image-prev"
    },
})
// SINGLE SERVICE OTHER SERVICE CAROUSEL
if ($('.service-multiple-carousel').length) {
    let serviceSwiper = new Swiper(".service-multiple-carousel", {
        modules: [Autoplay, Pagination, Navigation, Parallax, Grid],
        grid: {
            rows: 1,
            fill: 'row',
        },
        speed: 1000,
        spaceBetween: 0,
        spaceBetween: 50,
        loop: false,
        resistance: true,
        resistanceRatio: 0,
        watchSlidesProgress: true,
        parallax: true,
        autoplay: {
            delay: 3000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
        },
        navigation: {
            nextEl: '.service-multiple-next',
            prevEl: '.service-multiple-prev',
        },
        breakpoints: {
            768: {
                slidesPerView: 1,
                grid: {
                    rows: 1,
                    fill: 'row',
                },
            },
            1024: {
                slidesPerView: 1,
                grid: {
                    rows: 2,
                    fill: 'row',
                },
            },
        },
    });
}


// PAGE ABOUT GALLERY
var featuredBlogCarousel = new Swiper(".gallery-carousel", {
    modules: [Autoplay, Pagination, Navigation, Parallax],
    slidesPerView: 1,
    speed: 1000,
    spaceBetween: 0,
    loop: false,
    parallax: true,
    autoplay: {
        delay: 5000,
    },
    navigation: {
        nextEl: ".gallery-next",
        prevEl: ".gallery-prev"
    },
})

// SINGLE PROJECT CAROUSEL
if ($('.other-project-carousel').length) {
    let serviceSwiper = new Swiper(".other-project-carousel", {
        modules: [Autoplay, Pagination, Navigation,],
        speed: 1500,
        spaceBetween: 28,
        loop: false,
        resistance: true,
        resistanceRatio: 0,
        watchSlidesProgress: true,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
        },
        navigation: {
            nextEl: '.project-next',
            prevEl: '.project-prev',
        },
        breakpoints: {
            0: {
                slidesPerView: 1,
            },
            481: {
                slidesPerView: 1,
            },
            768: {
                slidesPerView: 2,
            },
            1366: {
                slidesPerView: 2,
            },
        },
    });
};

let referencesbottomCarousel = new Swiper(".references-bottom-carousel", {
    modules: [Autoplay],
    slidesPerView: 4,
    speed: 5000,
    spaceBetween: 30,
    loop: true,
    allowTouchMove: false,
    autoplay: {
        delay: 1,
        disableOnInteraction: false
    },
    breakpoints: {
        0: {
            slidesPerView: 2,
        },
        481: {
            slidesPerView: 2,
        },
        991: {
            slidesPerView: 2,
        },
        1024: {
            slidesPerView: 3,
        },
        1366: {
            slidesPerView: 5,
        },
    },
});


// SINGLE PROJECT CAROUSEL
let singleProjectParams = {
    modules: [Navigation, Parallax, Autoplay],
    speed: 1000,
    slidesPerView: 1,
    parallax: true,
    resistance: true,
    resistanceRatio: 0,
    navigation: {
        prevEl: '.hero-prev',
        nextEl: '.hero-next',
    },
    autoplay: {
        delay: 10000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
    },
    on: {
        slideChange: function () {
            let heroThumbs = document.querySelectorAll('.hero-tools .hero-thumbs .item');
            heroThumbs.forEach((item, index) => {
                index == this.realIndex ? item.classList.add('is-active') : item.classList.remove('is-active');
            });
        },
        autoplayTimeLeft: function (swiper, timeLeft, percentage) {
            this.el.querySelector('.swiper-slide-active .slide .content .inner .line .fill').style.height = (100 - (percentage * 100).toFixed(1)) + '%';
        }
    }
}
let singleProject = new Swiper('.single-project-carousel', singleProjectParams);

// Hero Thumbs Click Event
let projectThumbs = document.querySelectorAll('.hero-tools .hero-thumbs .item');
projectThumbs.forEach((item, index) => {
    item.addEventListener('click', () => {
        singleProject.slideTo(index);
    });
});