// Mobil için initMap
function initMapMobile() {
    const map = new google.maps.Map(document.getElementById("map"), {
        mapId: 'a355791c19ebfc9c',
        center: latLng,
        zoom: mapZoom,
        streetViewControl: false,
        zoomControl: false,
        disableDefaultUI: true,
        scrollwheel: false,
    });

    const markerView = new google.maps.marker.AdvancedMarkerView({
        map,
        position: latLng,
        content: mapMarker,
    });

    google.maps.event.addListener(markerView, 'click', function() {
        window.open(mapMarker.href, mapMarker.target);
    });
}

// Masaüstü için initMap
function initMapDesktop() {
    const map = new google.maps.Map(document.getElementById("map"), {
        mapId: 'a355791c19ebfc9c',
        center: latLng,
        zoom: mapZoom,
        streetViewControl: false,
        zoomControl: false,
        disableDefaultUI: true,
        scrollwheel: false,
    });

    const markerView = new google.maps.marker.AdvancedMarkerView({
        map,
        position: latLng,
        content: mapMarker,
    });

    google.maps.event.addListener(markerView, 'click', function() {
        window.open(mapMarker.href, mapMarker.target);
    });
}

// Mobil veya masaüstüne göre doğru initMap işlevini belirle
const windowWidth = $(window).width();
if (windowWidth > 900) {
    window.initMap = initMapDesktop;
} else {
    window.initMap = initMapMobile;
}
