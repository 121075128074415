// GSAP -------------------------------------------------------------
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger'
import DrawSVGPlugin from 'gsap/DrawSVGPlugin'
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import SplitText from 'gsap/SplitText';
gsap.registerPlugin(ScrollTrigger, DrawSVGPlugin, ScrollToPlugin, SplitText);
window.ScrollTrigger = ScrollTrigger;
window.gsap = gsap;

gsap.fromTo("#leftShape", {
    drawSVG: "1",
}, {
    drawSVG: "100%",
    ease: "none",
    scrollTrigger: {
        trigger: "#leftShape",
        scrub: 1,
        start: "top bottom",
        end: "max",
    },
    duration: 2,
});
gsap.fromTo("#leftShapeTwo", {
    drawSVG: "1",
}, {
    drawSVG: "100%",
    ease: "none",
    scrollTrigger: {
        trigger: "#leftShapeTwo",
        scrub: 1,
        start: "top bottom",
        end: "max",
    },
    duration: 2,
});
gsap.fromTo("#rightShape", {
    drawSVG: "1",
}, {
    drawSVG: "100%",
    ease: "none",
    scrollTrigger: {
        trigger: "#rightShape",
        scrub: 1,
        start: "top bottom",
        end: "max",
    },
    duration: 2,
});
gsap.fromTo("#rightShapeTwo", {
    drawSVG: "1",
}, {
    drawSVG: "100%",
    ease: "none",
    scrollTrigger: {
        trigger: "#rightShapeTwo",
        scrub: 1,
        start: "top bottom",
        end: "max",
    },
    duration: 2,
});