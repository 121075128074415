// Global Variables -------------------------------------------------
global.body = document.querySelector('body');
global.header = document.querySelector('header');
global.footer = document.querySelector('footer');
global.main = document.querySelector('main');
global.windowWidth = window.innerWidth;
global.windowHeight = window.innerHeight;
global.mobileLimit = 1280;

// jQuery ----------------------------------------------------------- 
import jQuery from 'jquery'
window.jQuery = jQuery
window.$ = jQuery

// FancyBox ---------------------------------------------------------
import { Fancybox } from '@fancyapps/ui'
window.Fancybox = Fancybox
// FANCYBOX | SETTINGS
// ------------------------------------------------------------
Fancybox.defaults.placeFocusBack = 0;
Fancybox.defaults.autoFocus = false;
Fancybox.defaults.trapFocus = false;
Fancybox.defaults.dragToClose = false;
// FANCYBOX | CUSTOM OPTIONS
// ------------------------------------------------------------

Fancybox.bind("[data-fancybox]", {});